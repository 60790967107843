export const APP_DATA_KEYS = {
    // App data keys
    FEATURES: "FEATURES",
    APP_REGION: "APP_REGION",
    APP_ENV: "APP_ENV",
    APP_UPDATE_AVAILABLE: "APP_UPDATE_AVAILABLE",
    APP_MOCK_NETWORK: "APP_MOCK_NETWORK",
}

class AppData {

    save(key, value, inSessionStorage) {
        if (value === null || value === undefined) {
            if (inSessionStorage) {
                sessionStorage.removeItem(key);
            } else {
                localStorage.removeItem(key);
            }
        } else {
            if (inSessionStorage) {
                sessionStorage.setItem(key, JSON.stringify(value));
            } else {
                localStorage.setItem(key, JSON.stringify(value));
            }
        }
    }

    get(key, inSessionStorage) {
        let value;
        if (inSessionStorage) {
            value = sessionStorage.getItem(key);
        } else {
            value = localStorage.getItem(key);
        }
        try {
            return JSON.parse(value);
        } catch (e) {
            console.log(`Error while parsing data for ${key} with value ${value}`, e);
            return null;
        }
    }


}

const AppDataStore = new AppData();

export default AppDataStore;