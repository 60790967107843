import React from 'react';
import './routing.css';
import {Route, Switch, withRouter} from "react-router-dom";
import AppLoader from "./components/app-loader";
import AppError from "./components/app-error";
import {PUMAMetricHelper} from "@amzn/dolphin-web-framework";

const PreRTFC = React.lazy(() => import("./components/pre/pre-RTFC"));
const CreateContainerScan = React.lazy(() => import("./components/pre/create-container-scan"));
const StowPackagesScanPackage = React.lazy(() => import("./components/pre/stow-packages-scan-package"));
const StowPackagesScanLocation = React.lazy(() => import("./components/pre/stow-packages-scan-location"));
const CloseContainerScan = React.lazy(() => import("./components/pre/close-container-scan"));
const MovePalletScan = React.lazy(() => import("./components/pre/move-pallet-scan"));
const MovePalletLocationScan = React.lazy(() => import("./components/pre/move-pallet-location-scan"));
const CreateContainerMoveOverflowScan = React.lazy(() => import("./components/pre/create-container-move-overflow-scan"));
const DepartPalletScan = React.lazy(() => import("./components/pre/depart-pallet-scan"));
const CreateContainerMovePalletScan = React.lazy(() => import("./components/pre/create-container-move-pallet-scan"));
const MovePalletOverflowScan = React.lazy(() => import("./components/pre/move-pallet-overflow-scan"));
const DepartSubtaskSelector = React.lazy(() => import("./components/pre/depart-subtask-selector"))
const DepartPalletAcknowledgement = React.lazy(() => import("./components/post/depart-pallet-acknowledgement"))
const DepartPackageScan = React.lazy(() => import("./components/pre/depart-package-scan"))
const ReprintLabelOpenPallet = React.lazy(() => import("./components/pre/reprint-label-open-pallet"))
const ReprintLabelScanPackage = React.lazy(() => import("./components/pre/reprint-label-scan-package"))
const ReprintLabelSuccess = React.lazy(() => import("./components/pre/reprint-label-success"))


export const ROUTE_PATH = {
    CREATE_CONTAINER_SCAN: "/create-container-scan",
    STOW_PACKAGES_SCAN_PACKAGE: "/stow-packages-scan-package",
    STOW_PACKAGES_SCAN_LOCATION: "/stow-packages-scan-location",
    CLOSE_CONTAINER_SCAN: "/close-container-scan",
    MOVE_PALLET_SCAN: "/move-pallet-scan",
    MOVE_PALLET_LOCATION_SCAN: "/move-pallet-location-scan",
    CREATE_CONTAINER_MOVE_OVERFLOW_SCAN: "/create-container-move-overflow-scan",
    DEPART_PALLET_SCAN: "/depart-pallet-scan",
    CREATE_CONTAINER_MOVE_PALLET_SCAN: "/create-container-move-pallet-scan",
    PALLET_ID: "palletId",
    MOVE_PALLET_OVERFLOW_SCAN: "/move-pallet-overflow-scan",
    DEPART_SUBTASK_SELECTOR: "/depart-subtask-selector",
    DEPART_PALLET_ACKNOWLEDGEMENT: "/depart-pallet-acknowledgement",
    DEPART_PACKAGE_SCAN: "/depart-package-scan",
    REPRINT_LABEL_OPEN_PALLET: "/reprint-label-open-pallet",
    REPRINT_LABEL_SCAN_PACKAGE: "/reprint-label-scan-package",
    REPRINT_LABEL_SUCCESS: "/reprint-label-success",
    ENTRY: "/"
};

function Routing(props) {

    props.history.listen((args) => {
        const oldPathName = props.location.pathname;
        const newPathName = args.pathname;
        if (oldPathName !== newPathName) {
            PUMAMetricHelper.publishCountToDolphinCSM(newPathName);
        }
    });

    return (
        <AppError>
            <React.Suspense fallback={<AppLoader/>}>
                <Switch>
                    <Route exact path={ROUTE_PATH.ENTRY} component={PreRTFC}/>
                    <Route exact path={ROUTE_PATH.CREATE_CONTAINER_SCAN} component={CreateContainerScan}/>
                    <Route exact path={ROUTE_PATH.STOW_PACKAGES_SCAN_PACKAGE} component={StowPackagesScanPackage}/>
                    <Route exact path={ROUTE_PATH.STOW_PACKAGES_SCAN_LOCATION} component={StowPackagesScanLocation}/>
                    <Route exact path={ROUTE_PATH.CLOSE_CONTAINER_SCAN} component={CloseContainerScan}/>
                    <Route exact path={ROUTE_PATH.MOVE_PALLET_SCAN} component={MovePalletScan}/>
                    <Route exact path={ROUTE_PATH.MOVE_PALLET_LOCATION_SCAN} component={MovePalletLocationScan}/>
                    <Route exact path={ROUTE_PATH.CREATE_CONTAINER_MOVE_OVERFLOW_SCAN}
                           component={CreateContainerMoveOverflowScan}/>
                    <Route exact path={ROUTE_PATH.DEPART_PALLET_SCAN} component={DepartPalletScan}/>
                    <Route exact path={ROUTE_PATH.MOVE_PALLET_OVERFLOW_SCAN} component={MovePalletOverflowScan}/>
                    <Route exact path={ROUTE_PATH.CREATE_CONTAINER_MOVE_PALLET_SCAN}
                           component={CreateContainerMovePalletScan}/>
                    <Route exact path={ROUTE_PATH.DEPART_SUBTASK_SELECTOR} component={DepartSubtaskSelector}/>
                    <Route exact path={ROUTE_PATH.DEPART_PALLET_ACKNOWLEDGEMENT}
                           component={DepartPalletAcknowledgement}/>
                    <Route exact path={ROUTE_PATH.DEPART_PACKAGE_SCAN} component={DepartPackageScan}/>
                    <Route exact path={ROUTE_PATH.REPRINT_LABEL_OPEN_PALLET} component={ReprintLabelOpenPallet}/>
                    <Route exact path={ROUTE_PATH.REPRINT_LABEL_SCAN_PACKAGE} component={ReprintLabelScanPackage}/>
                    <Route exact path={ROUTE_PATH.REPRINT_LABEL_SUCCESS} component={ReprintLabelSuccess}/>
                </Switch>
            </React.Suspense>
        </AppError>
    );
}

export default withRouter(Routing);