import helpTokens from "@amzn/meridian-tokens/base/icon/help";
import React, {useContext, useEffect} from 'react';
import {HelpView, TopNavigationView} from "@amzn/dolphin-uiweb-framework";
import Column from "@amzn/meridian/column";
import AppBackdrop from "./app-backdrop";
import {AppContext} from "../context/app-context";
import {withRouter} from 'react-router-dom';
import helpImage from '../assets/images/help_coming_up.png';

const BACK_PRESSED = "BACK_PRESSED";

let prevBackPressedListener = undefined;

function setBackPressedEventListener(listener) {
    window.removeEventListener(BACK_PRESSED, prevBackPressedListener);
    prevBackPressedListener = listener;
    window.addEventListener(BACK_PRESSED, listener);
}

function AppTopNavigation(props) {

    const {appState, appActions} = useContext(AppContext);

    useEffect(() => {
        /**
         * The following code is listening to the back press events
         * The code is supposed to close any views like help or backdrop view
         * in case of either are not visible we call onBackPressed function from the appState
         * the default behaviour is to go back in history
         */
        setBackPressedEventListener(() => {
            if(appState.helpOptions.open) {
                appActions.closeHelp();
            } else if(appState.backOptions.open) {
                appActions.closeBackDrop();
            } else if (appState.onBackPressed) {
                appState.onBackPressed.call();
            } else {
                props.history.goBack();
            }
        });
    }, [appState.onBackPressed, appState.backOptions, appState.helpOptions]);

    return (
        <Column
            id={"app-header"}
            width={"100%"}
        >
            <TopNavigationView
                backgroundType={appState.headerType}
                backgroundColor={""}
                title={appState.title}
                onMenuButtonClicked={appState.onBackPressed}
                optionsMenu={[{icon: helpTokens, onClick: appActions.openHelp}]}/>
            {appState.helpOptions.open &&
            <HelpView
                help_icon={helpImage}
                onClose={appActions.closeHelp}
                {...appState.helpOptions} />}
            {appState.backOptions.open &&
            <AppBackdrop
                onClose={appActions.closeBackDrop}
                {...appState.backOptions}/>}
        </Column>
    );
}

export default withRouter(AppTopNavigation);