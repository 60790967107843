import {Constants} from "@amzn/dolphin-web-framework";

export function getPUMARegion() {
    // the hostname pattern is region-env-*.*
    const hostname = window.location.hostname;
    if (hostname.startsWith(Constants.RegionLowerCase.REGION_NA)) return Constants.RegionLowerCase.REGION_NA;
    else if (hostname.startsWith(Constants.RegionLowerCase.REGION_EU)) return Constants.RegionLowerCase.REGION_EU;
    else if (hostname.startsWith(Constants.RegionLowerCase.REGION_FE)) return Constants.RegionLowerCase.REGION_JP;
    else if (hostname.startsWith(Constants.RegionLowerCase.REGION_IN)) return Constants.RegionLowerCase.REGION_EU;
    return Constants.RegionLowerCase.REGION_NA;
}

export function getPUMACountry() {
    // the hostname pattern is region-env-*.*
    const hostname = window.location.hostname;
    if (hostname.startsWith(Constants.RegionLowerCase.REGION_NA)) return Constants.Region.REGION_US;
    else if (hostname.startsWith(Constants.RegionLowerCase.REGION_EU)) return Constants.Region.REGION_UK;
    else if (hostname.startsWith(Constants.RegionLowerCase.REGION_FE)) return Constants.Region.REGION_JP;
    else if (hostname.startsWith(Constants.RegionLowerCase.REGION_IN)) return Constants.Region.REGION_IN;
    return Constants.Region.REGION_US;
}

export function getPUMAEnv() {
    // the hostname pattern is region-env-*.*
    const hostname = window.location.hostname;
    const identifier = hostname.split(".")[0]; // we intent to get the region-env-*
    const env = identifier.split("-")[1];
    if (env === Constants.Stage.INTEG) return Constants.Stage.TEST;
    else if (env === Constants.Stage.MASTER) return Constants.Stage.TEST;
    else if (env === Constants.Stage.PROD) return Constants.Stage.PROD;
    return Constants.Stage.TEST;
}