const METRIC_KEYS = {
    DEPART : "DEPART",
    DEPART_OPEN_PALLET : "depart_open_pallet",
    DEPART_CLOSE_PALLET : "depart_close_pallet",
    DEPART_MOVE_CONTAINER : "depart_move_container",
    DEPART_DEPART_PALLET : "depart_depart_pallet",
    DEPART_MOVE_PALLET : "depart_move_pallet",
    DEPART_GET_PALLET_LABEL : "depart_get_pallet_label",

    DEPART_SCAN_PACKAGE : "depart_scan_package",
    DEPART_SCAN_PALLET : "depart_scan_pallet",
    DEPART_SCAN_LOCATION : "depart_scan_location",

    DEPART_SET_WORKFLOW : "depart_set_workflow",
    USER_CLICK_BUTTON : "user_click_button"
}

export {METRIC_KEYS};