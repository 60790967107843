import React, {useEffect, useState} from "react";
import AppTopNavigation from "./components/app-top-navigation";
import Routing from "./routing";
import RTFCActivityViewModelContextProvider from "./context/depart-view-model-context";
import AppContextProvider from "./context/app-context";
import Column from "@amzn/meridian/column";
import {IntlProvider} from "react-intl";
import LocaleManagerInstance from "./locale/locale-manager";
import {FeatureManager, MeshClient} from "@amzn/dolphin-web-framework";
import AppDataStore, {APP_DATA_KEYS} from "./components/app-data";
import {Loader} from "@amzn/dolphin-uiweb-framework";

export default function App() {
    const locale = LocaleManagerInstance.getLocale();
    const messages = LocaleManagerInstance.getMessages();
    const [loadFeatures, setLoadFeatures] = useState(true);

    useEffect( () => {
        async function initialize() {
            await MeshClient.connectWebSocket();
            FeatureManager.getFeatures(AppDataStore.get(APP_DATA_KEYS.APP_ENV),
                AppDataStore.get(APP_DATA_KEYS.APP_REGION)).then(()=>{
                setLoadFeatures(false);
            });
        }
        initialize();
        return () => {
            MeshClient.closeWebSocketConnection();
        };
    },[]);
    return (
        <IntlProvider locale={locale} messages={messages}>
            <AppContextProvider>
                <RTFCActivityViewModelContextProvider>
                    {loadFeatures ? <Loader/> : null}
                    <Column height="100%" spacing="none">
                        <AppTopNavigation/>
                        <Column id={"container"} className="container" width="100%" height="100%" spacingInset="none">
                            <Routing/>
                        </Column>
                    </Column>
                </RTFCActivityViewModelContextProvider>
            </AppContextProvider>
        </IntlProvider>
    );
}