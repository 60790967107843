export const DEFAULT_VALUE = {
    title: "",
    backEnabled: true,
    helpEnabled: false,
    headerBackgroundColor: "#191919",
    headerType: "default",
    onBackPressed: null,
    currOnScanListener: null,
    helpOptions: {
        open: false,
        title: "",
        pageTitle: "",
        list: []
    },
    backOptions: {
        open: false,
        primaryButtonTitle: "Continue Staging",
        primaryButtonOnClick: () => {
        },
        secondaryButtonTitle: "End Staging & Exit",
        secondaryButtonOnClick: () => {
        }
    }
};

export const AppContextActions = {
    SETUP_HEADER: "SETUP_HEADER",
    SETUP_HELP: "SETUP_HELP",
    OPEN_HELP: "OPEN_HELP",
    CLOSE_HELP: "CLOSE_HELP",
    SET_HEADER_COLOR: "SET_HEADER_COLOR",
    OPEN_BACK_DROP: "OPEN_BACK_DROP",
    CLOSE_BACK_DROP: "CLOSE_BACK_DROP",
    SET_ON_SCAN_EVENT_LISTENER: "SET_ON_SCAN_EVENT_LISTENER",
    CLEAR_ON_SCAN_EVENT_LISTENER: "CLEAR_ON_SCAN_EVENT_LISTENER",
    SET_HEADER_TYPE: "SET_HEADER_TYPE"
}

export const AppContextReducer = (state, action) => {
    switch (action.type) {
        /**
         * Header setup for back button and title, title doesn't change if not passed
         */
        case AppContextActions.SETUP_HEADER:
            return {...state, title: action.value.title || state.title, onBackPressed: action.value.onBackPressed};

        /**
         * Help options
         */
        case AppContextActions.SETUP_HELP:
            return {...state, helpEnabled: action.value.enabled, helpOptions: {...action.value.options, open: false}};
        case AppContextActions.OPEN_HELP:
            return {...state, helpOptions: {...state.helpOptions, open: true}};
        case AppContextActions.CLOSE_HELP:
            return {...state, helpOptions: {...state.helpOptions, open: false}};

        /**
         * Header background
         */
        case AppContextActions.SET_HEADER_COLOR:
            return {...state, headerBackgroundColor: action.value};
        case AppContextActions.SET_HEADER_TYPE:
            return {...state, headerType: action.value}

        /**
         * Backdrop options
         */
        case AppContextActions.OPEN_BACK_DROP:
            return {...state, backOptions: {...state.backOptions, ...action.value, open: true}};
        case AppContextActions.CLOSE_BACK_DROP:
            return {...state, backOptions: {...state.backOptions, open: false}};

        /**
         * On Scan Event Listener
         */
        case AppContextActions.SET_ON_SCAN_EVENT_LISTENER:
            return {...state, currOnScanListener: action.value}
        case AppContextActions.CLEAR_ON_SCAN_EVENT_LISTENER:
            return {...state, currOnScanListener: null}

        /**
         * returning the same state
         */
        default:
            return state;
    }
}