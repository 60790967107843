import React, {createContext, useReducer} from "react";
import {injectIntl} from "react-intl";
import {AppContextActions, AppContextReducer, DEFAULT_VALUE} from "../reducer/app-context-reducer";

export const AppContext = createContext(DEFAULT_VALUE);

const AppContextProvider = (props) => {
    const [appState, dispatchApp] = useReducer(AppContextReducer, DEFAULT_VALUE);

    const formatMessage = (id, options) => {
        return props.intl.formatMessage({id: id}, options);
    }

    const setupHeader = (title, onBackPressed) => {
        dispatchApp({type: AppContextActions.SETUP_HEADER, value: {title, onBackPressed}});
    }

    const setupHelp = (enabled, options) => {
        dispatchApp({type: AppContextActions.SETUP_HELP, value: {enabled, options}});
    }

    const openHelp = () => {
        dispatchApp({type: AppContextActions.OPEN_HELP});
    }

    const closeHelp = () => {
        dispatchApp({type: AppContextActions.CLOSE_HELP});
    }

    const setHeaderBackground = (color) => {
        dispatchApp({type: AppContextActions.SET_HEADER_COLOR, value: color});
    }

    const setHeaderType = (type) => {
        dispatchApp({type: AppContextActions.SET_HEADER_TYPE, value: type});
    }

    const openBackDrop = (options) => {
        dispatchApp({type: AppContextActions.OPEN_BACK_DROP, value: options});
    }

    const closeBackDrop = () => {
        dispatchApp({type: AppContextActions.CLOSE_BACK_DROP});
    }

    const setOnScanEventListener = (listener) => {
         dispatchApp({type: AppContextActions.SET_ON_SCAN_EVENT_LISTENER, value: listener});
    }

    const clearOnScanEventListener = () => {
        dispatchApp({type: AppContextActions.CLEAR_ON_SCAN_EVENT_LISTENER});
    }

    return (
        <AppContext.Provider value={{
            appState, formatMessage, appActions: {
                setupHeader,
                setupHelp,
                openHelp,
                closeHelp,
                openBackDrop,
                closeBackDrop,
                setHeaderBackground,
                setHeaderType,
                setOnScanEventListener,
                clearOnScanEventListener
            }
        }}>
            {props.children}
        </AppContext.Provider>
    )
}

export default injectIntl(AppContextProvider);