import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import '@amzn/dolphin-uiweb-framework/dist/index.css';
import AppDataStore, {APP_DATA_KEYS} from './components/app-data';
import { HashRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import "@amzn/meridian-tokens/base/font/amazon-ember.css";
import App from "./app";
import {getAnalyticsRegion, saveDataForApp} from "./bridge/utils";
import {DataHandler, Logger, MobileAnalyticsHelper, PUMAMetricHelper} from "@amzn/dolphin-web-framework";
import {getLoggerURL} from "./network/base/base-urls";
import {getPUMACountry, getPUMARegion, getPUMAEnv} from "./network/base/utils";
import {PUMA_APP_ID, PUMA_MODULE_NAME} from "./constants/PUMAConstants";
import {SoundType} from "./sound/SoundManager";
import LocaleManagerInstance from "./locale/locale-manager";

saveDataForApp();
DataHandler.parseSearchParams();
Logger.setLogger(getLoggerURL(), AppDataStore.get(APP_DATA_KEYS.APP_ENV));
PUMAMetricHelper.pumaInit(getPUMACountry(), getPUMARegion(), getPUMAEnv(), PUMA_APP_ID, PUMA_MODULE_NAME);
MobileAnalyticsHelper.initializeAnalytics(getAnalyticsRegion());
function playSilentSound() {
    SoundType.SILENT.play().catch(error => {
        Logger.log.warn("Unable to play silent sound : " + error);
    });
    document.removeEventListener('click', playSilentSound);
}

/**
 * Playing a silent sound on first click to capture the user gesture.
 */
document.addEventListener('click', playSilentSound);
document.getElementsByTagName(`html`)[0].setAttribute("dir", LocaleManagerInstance.getDirection());

ReactDOM.render((
    <HashRouter>
        <App/>
    </HashRouter>
), document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
    onUpdate: (serviceWorkerRegistration) => {
        const registrationWaiting = serviceWorkerRegistration.waiting;
        if (registrationWaiting) {
            registrationWaiting.postMessage({ type: 'SKIP_WAITING' });
            registrationWaiting.addEventListener('statechange', e => {
                if (e.target.state === 'activated') {
                    window.location.reload();
                }
            });
        }
    }
});