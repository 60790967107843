import React, {createContext, useReducer} from "react";
import {departReducer} from "../reducer/depart-view-model-reducer";

export const DepartViewModelContext = createContext();

const RTFCActivityViewModelContextProvider = (props) => {
    const initState = {
        palletScannable : "",
        locationScannable: "",
        packageScannable: "",
        activeWorkflow: ""
    };

    const [stateDepart, dispatchDepart] = useReducer(departReducer, initState)

    return (
        <DepartViewModelContext.Provider value={{stateDepart, dispatchDepart}}>
            {props.children}
        </DepartViewModelContext.Provider>
    );
}

export default RTFCActivityViewModelContextProvider;