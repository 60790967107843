import {addLocaleData} from "react-intl";
import STRINGS from "./strings";

import messagesEN from './locale-strings/en.json';
import messagesDE from './locale-strings/de.json';
import messagesES from './locale-strings/es.json';
import messagesFR from './locale-strings/fr.json';
import messagesHI from './locale-strings/hi.json';
import messagesIT from './locale-strings/it.json';
import messagesJA from './locale-strings/ja.json';
import messagesPT from './locale-strings/pt.json';
import messagesRO from './locale-strings/ro.json';
import messagesAR from './locale-strings/ar.json';
import messagesTR from './locale-strings/tr.json';
import messagesNL from './locale-strings/nl.json';
import de from 'react-intl/locale-data/de';
import en from 'react-intl/locale-data/en';
import es from 'react-intl/locale-data/es';
import fr from 'react-intl/locale-data/fr';
import hi from 'react-intl/locale-data/hi';
import it from 'react-intl/locale-data/it';
import ja from 'react-intl/locale-data/ja';
import pt from 'react-intl/locale-data/pt';
import ro from 'react-intl/locale-data/ro';
import ar from 'react-intl/locale-data/ar';
import nl from 'react-intl/locale-data/nl';
import tr from 'react-intl/locale-data/tr';
import {DataHandler} from "@amzn/dolphin-web-framework";

const LOCALE_MESSAGE_MAP = {
    "en": messagesEN,
    "de": messagesDE,
    "es": messagesES,
    "fr": messagesFR,
    "hi": messagesHI,
    "it": messagesIT,
    "ja": messagesJA,
    "nl": messagesNL,
    "tr": messagesTR,
    "pt": messagesPT,
    "ro": messagesRO,
    "ar": messagesAR
};

addLocaleData(en);
addLocaleData(de);
addLocaleData(es);
addLocaleData(fr);
addLocaleData(hi);
addLocaleData(it);
addLocaleData(ja);
addLocaleData(pt);
addLocaleData(ro);
addLocaleData(ar);
addLocaleData(nl);
addLocaleData(tr);

const DEFAULT_LOCALE = "en";
const DEFAULT_MESSAGES = LOCALE_MESSAGE_MAP[DEFAULT_LOCALE];

/**
 * Loads and returns locale messages, also loads missing strings from the DEFAULT_LOCALE
 * @param locale
 */
function getLocaleMessages(locale) {
    const missingStrings = [];
    const messages = LOCALE_MESSAGE_MAP[locale] || {};
    Object.keys(STRINGS).forEach(str => {
        if (!messages[str]) {
            messages[str] = DEFAULT_MESSAGES[str];
            missingStrings.push(str);
        }
    });
    missingStrings.length && console.log(`${locale} has missing strings ${missingStrings}`);
    return messages;
}

class LocaleManager {

    constructor() {
        this.messagesLocaleMap = new Map();
    }

    /**
     * Get the user locale or returns the DEFAULT_LOCALE if none is set
     * @returns {any | string}
     */
    getLocale() {
        switch (DataHandler.getUserLocale()) {
            case "de":
            case "de-CH":
            case "de-AT":
            case "de-LU":
            case "de-LI":
                return "de";
            case "es":
            case "es-AR":
            case "es-GT":
            case "es-CR":
            case "es-PA":
            case "es-DO":
            case "es-MX":
            case "es-VE":
            case "es-CO":
            case "es-PE":
            case "es-EC":
            case "es-CL":
            case "es-UY":
            case "es-PY":
            case "es-BO":
            case "es-SV":
            case "es-HN":
            case "es-NI":
            case "es-PR":
                return "es";
            case "fr":
            case "fr-BE":
            case "fr-CA":
            case "fr-CH":
            case "fr-LU":
                return "fr";
            case "hi":
            case "hi-IN":
                return "hi";
            case "it":
            case "it-CH":
                return "it";
            case "ja":
            case "jp":
                return "ja";
            case "pt":
            case "pt-BR":
            case "pt-PT":
                return "pt";
            case "ro":
            case "ro-RO":
                return "ro";
            case "ar":
            case "ar-SA":
            case "ar-IQ":
            case "ar-EG":
            case "ar-LY":
            case "ar-DZ":
            case "ar-MA":
            case "ar-TN":
            case "ar-OM":
            case "ar-YE":
            case "ar-SY":
            case "ar-JO":
            case "ar-LB":
            case "ar-KW":
            case "ar-AE":
            case "ar-BH":
            case "ar-QA":
                return "ar";
            case "nl":
            case "nl-NL":
            case "nl-BE":
                return "nl"
            case "tr":
            case "tr-TR":
                return "tr"
            default:
                return DEFAULT_LOCALE
        }
    }

    /**
     *
     * @returns {*}
     */
    getMessages() {
        let messages = this.messagesLocaleMap.get(this.getLocale());
        // if we don't have the messages in the map...load them
        if (!messages) {
            messages = getLocaleMessages(this.getLocale());
            this.messagesLocaleMap.set(this.getLocale(), messages);
        }
        return messages;
    }
    getDirection() {
        return this.getLocale() === "ar" ? "rtl" : "ltr";
    }
}

const LocaleManagerInstance = new LocaleManager();
export default LocaleManagerInstance;