import AppDataStore, {APP_DATA_KEYS} from "../../components/app-data";
import {saveDataForApp} from "../../bridge/utils";
import {Constants} from "@amzn/dolphin-web-framework";

const BASE_URL_ENV_REGION_MAP = new Map();
// "STAGE-REGION" => url
BASE_URL_ENV_REGION_MAP.set(`${Constants.Region.REGION_NA}-${Constants.Stage.BETA}`, Constants.DolphinURL.NA.PREPROD);
BASE_URL_ENV_REGION_MAP.set(`${Constants.Region.REGION_EU}-${Constants.Stage.BETA}`, Constants.DolphinURL.EU.PREPROD);
BASE_URL_ENV_REGION_MAP.set(`${Constants.Region.REGION_IN}-${Constants.Stage.BETA}`, Constants.DolphinURL.IN.PREPROD);

BASE_URL_ENV_REGION_MAP.set(`${Constants.Region.REGION_NA}-${Constants.Stage.GAMMA}`, Constants.DolphinURL.NA.PREPROD);
BASE_URL_ENV_REGION_MAP.set(`${Constants.Region.REGION_EU}-${Constants.Stage.GAMMA}`, Constants.DolphinURL.EU.PREPROD);
BASE_URL_ENV_REGION_MAP.set(`${Constants.Region.REGION_FE}-${Constants.Stage.GAMMA}`, Constants.DolphinURL.FE.PREPROD);
BASE_URL_ENV_REGION_MAP.set(`${Constants.Region.REGION_IN}-${Constants.Stage.GAMMA}`, Constants.DolphinURL.IN.PREPROD);

BASE_URL_ENV_REGION_MAP.set(`${Constants.Region.REGION_NA}-${Constants.Stage.PROD}`, Constants.DolphinURL.NA.PROD);
BASE_URL_ENV_REGION_MAP.set(`${Constants.Region.REGION_EU}-${Constants.Stage.PROD}`, Constants.DolphinURL.EU.PROD);
BASE_URL_ENV_REGION_MAP.set(`${Constants.Region.REGION_FE}-${Constants.Stage.PROD}`, Constants.DolphinURL.FE.PROD);
BASE_URL_ENV_REGION_MAP.set(`${Constants.Region.REGION_IN}-${Constants.Stage.PROD}`, Constants.DolphinURL.IN.PROD);


export function getBaseURL() {
    // getting region and env values from AppData
    const region = AppDataStore.get(APP_DATA_KEYS.APP_REGION);
    const env = AppDataStore.get(APP_DATA_KEYS.APP_ENV).toLowerCase();
    const regionEnv = `${region}-${env}`;
    return BASE_URL_ENV_REGION_MAP.has(regionEnv) ? BASE_URL_ENV_REGION_MAP.get(regionEnv) : null;
}

const KATAL_LOGGER_URL_MAP = new Map();
// "STAGE-REGION" => url
KATAL_LOGGER_URL_MAP.set(`${Constants.Region.REGION_NA}-${Constants.Stage.BETA}`,  "https://berok9b19d.execute-api.us-west-2.amazonaws.com/prod/v1/log");
KATAL_LOGGER_URL_MAP.set(`${Constants.Region.REGION_EU}-${Constants.Stage.BETA}`, "https://berok9b19d.execute-api.us-west-2.amazonaws.com/prod/v1/log");
KATAL_LOGGER_URL_MAP.set(`${Constants.Region.REGION_FE}-${Constants.Stage.BETA}`,  "https://berok9b19d.execute-api.us-west-2.amazonaws.com/prod/v1/log");
KATAL_LOGGER_URL_MAP.set(`${Constants.Region.REGION_IN}-${Constants.Stage.BETA}`, "https://berok9b19d.execute-api.us-west-2.amazonaws.com/prod/v1/log");

KATAL_LOGGER_URL_MAP.set(`${Constants.Region.REGION_NA}-${Constants.Stage.GAMMA}`, "https://afzic7cvba.execute-api.us-east-1.amazonaws.com/prod/v1/log");
KATAL_LOGGER_URL_MAP.set(`${Constants.Region.REGION_EU}-${Constants.Stage.GAMMA}`, "https://9z103bbq47.execute-api.eu-west-1.amazonaws.com/prod/v1/log");
KATAL_LOGGER_URL_MAP.set(`${Constants.Region.REGION_FE}-${Constants.Stage.GAMMA}`, "https://wcv81bfdp2.execute-api.us-west-2.amazonaws.com/prod/v1/log");
KATAL_LOGGER_URL_MAP.set(`${Constants.Region.REGION_IN}-${Constants.Stage.GAMMA}`, "https://9z103bbq47.execute-api.eu-west-1.amazonaws.com/prod/v1/log");

KATAL_LOGGER_URL_MAP.set(`${Constants.Region.REGION_NA}-${Constants.Stage.PROD}`, "https://h1ofetfbo9.execute-api.us-east-1.amazonaws.com/prod/v1/log");
KATAL_LOGGER_URL_MAP.set(`${Constants.Region.REGION_EU}-${Constants.Stage.PROD}`, "https://lw3iay2eo3.execute-api.eu-west-1.amazonaws.com/prod/v1/log");
KATAL_LOGGER_URL_MAP.set(`${Constants.Region.REGION_FE}-${Constants.Stage.PROD}`, "https://qbi5lts049.execute-api.us-west-2.amazonaws.com/prod/v1/log");
KATAL_LOGGER_URL_MAP.set(`${Constants.Region.REGION_IN}-${Constants.Stage.PROD}`, "https://zrnlanor8e.execute-api.eu-west-1.amazonaws.com/prod/v1/log");

export function getLoggerURL() {
    saveDataForApp();
    // getting region and env values from AppData
    const region = AppDataStore.get(APP_DATA_KEYS.APP_REGION);
    const env = AppDataStore.get(APP_DATA_KEYS.APP_ENV).toLowerCase();
    const regionEnv = `${region}-${env}`;
    return KATAL_LOGGER_URL_MAP.has(regionEnv) ? KATAL_LOGGER_URL_MAP.get(regionEnv) : null;
}
