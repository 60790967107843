import {METRIC_KEYS} from "../models/enums/metric-keys";
import {MobileAnalyticsHelper} from "@amzn/dolphin-web-framework";

export const DEPART_ACTIONS = {
    SET_SCANNED_PACKAGE_ID : "SET_SCANNED_PACKAGE_ID",
    SET_SCANNED_PALLET_ID : "SET_SCANNED_PALLET_ID",
    SET_SCANNED_LOCATION_ID : "SET_SCANNED_LOCATION_ID",
    SET_ACTIVE_WORKFLOW : "SET_ACTIVE_WORKFLOW"
}

export const departReducer = (state, action) => {
    switch (action.type) {
        case DEPART_ACTIONS.SET_SCANNED_LOCATION_ID:
            MobileAnalyticsHelper.executeAPIAnalytics(METRIC_KEYS.DEPART_SCAN_LOCATION, Date.now(), false);
            return {...state, locationScannable: action.value};
        case DEPART_ACTIONS.SET_SCANNED_PACKAGE_ID:
            MobileAnalyticsHelper.executeAPIAnalytics(METRIC_KEYS.DEPART_SCAN_PACKAGE, Date.now(), false);
            return {...state, packageScannable: action.value};
        case DEPART_ACTIONS.SET_SCANNED_PALLET_ID:
            MobileAnalyticsHelper.executeAPIAnalytics(METRIC_KEYS.DEPART_SCAN_PALLET, Date.now(), false);
            return {...state, palletScannable: action.value};
        case DEPART_ACTIONS.SET_ACTIVE_WORKFLOW:
            return {...state, activeWorkflow: action.value}
        default:
            return state;
    }
}