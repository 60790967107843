import Row from "@amzn/meridian/row";
import Button from "@amzn/meridian/button";
import Text from "@amzn/meridian/text";
import Icon from "@amzn/meridian/icon";
import closeTokens from "@amzn/meridian-tokens/base/icon/close-large";
import Column from "@amzn/meridian/column";
import React from "react";

export default function AppBackdrop(props) {

    return <Column className={"backdrop"} spacingInset={"small"}>
        <Row alignmentHorizontal="right" spacing={"none"}>
            <Button type="icon" onClick={props.onClose}>
                <Text color={"inverted"}><Icon tokens={closeTokens}/></Text>
            </Button>
        </Row>
        <Row spacingInset={"medium"}>
            <Text type="h400" color="inverted">{"Back button pressed"}</Text>
        </Row>
        <Column spacingInset={"medium"}>
            <Text type="b200"
                  color="inverted">{"You tapped the back button. To ensure this wasn't accidental, we want to confirm your action once more."}</Text>
        </Column>
        <Column className={"footer"} spacingInset={"medium"}>
            <Button onClick={props.onClose}>{props.primaryButtonTitle}</Button>
            {props.secondaryButtonOnClick &&
            <Button type={"secondary"} onClick={props.secondaryButtonOnClick}>{props.secondaryButtonTitle}</Button>}
        </Column>
    </Column>;
}