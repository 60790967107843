const STRINGS = {
    operation_depart: "operation_depart",
    scan_package: "scan_package",
    dfs_select_depart_task: "dfs_select_depart_task",
    dfs_open_pallet: "dfs_open_pallet",
    dfs_open_pallet_desc: "dfs_open_pallet_desc",
    dfs_close_pallet: "dfs_close_pallet",
    dfs_close_pallet_desc: "dfs_close_pallet_desc",
    dfs_move_pallet: "dfs_move_pallet",
    dfs_move_pallet_desc: "dfs_move_pallet_desc",
    scan_staging_area: "scan_staging_area",
    scan_pallet: "scan_pallet",
    scan_pallet_location: "scan_pallet_location",
    stow_fragment_title: "stow_fragment_title",
    dfs_reprint_open_heading: "dfs_reprint_open_heading",
    dfs_reprint_open_msg: "dfs_reprint_open_msg",
    dfs_btn_cancel: "dfs_btn_cancel",
    dfs_btn_pallet_opened: "dfs_btn_pallet_opened",
    dfs_scan_pallet: "dfs_scan_pallet",
    dfs_attach_label_msg: "dfs_attach_label_msg",
    dfs_overflow_area_msg: "dfs_overflow_area_msg",
    dfs_overflow_area_heading: "dfs_overflow_area_heading",
    dfs_place_empty_pallet_msg: "dfs_place_empty_pallet_msg",
    dfs_scan_depart_pallet_msg: "dfs_scan_depart_pallet_msg",
    dfs_move_pallet_van_msg: "dfs_move_pallet_van_msg",
    dfs_depart_pallet: "dfs_depart_pallet",
    dfs_depart_pallets: "dfs_depart_pallets",
    dfs_depart_containers: "dfs_depart_containers",
    dfs_depart_package: "dfs_depart_package",
    dfs_depart_packages: "dfs_depart_packages",
    dfs_depart_pallet_desc: "dfs_depart_pallet_desc",
    dfs_place_package_depart_msg: "dfs_place_package_depart_msg",
    dfs_open_success_msg: "dfs_open_success_msg",
    dfs_stow_success_msg: "dfs_stow_success_msg",
    dfs_close_success_msg: "dfs_close_success_msg",
    dfs_depart_success_msg: "dfs_depart_success_msg",
    dfs_depart_success_msg_package: "dfs_depart_success_msg_package",
    error_invalid_package: "error_invalid_package",
    error_package_not_found: "error_package_not_found",
    error_invalid_barcode: "error_invalid_barcode",
    error_close_before_depart: "error_close_before_depart",
    error_invalid_package_type_mfn: "error_invalid_package_type_mfn",
    error_invalid_package_type_afn: "error_invalid_package_type_afn",
    error_unknown_container: "error_unknown_container",
    error_container_already_closed: "error_container_already_closed",
    error_unknown_constainer: "error_unknown_constainer",
    error_container_empty: "error_container_empty",
    error_container_empty_depart: "error_container_empty_depart",
    error_pallet_already_open: "error_pallet_already_open",
    error_no_container_for_resource: "error_no_container_for_resource",
    error_no_container_for_resource_stow_case: "error_no_container_for_resource_stow_case",
    error_pallet_already_departed: "error_pallet_already_departed",
    error_move_existing_pallet: "error_move_existing_pallet",
    error_requested_pallet_not_found: "error_requested_pallet_not_found",
    dfs_close_help_center_box: "dfs_close_help_center_box",
    dfs_close_help_center_title: "dfs_close_help_center_title",
    dfs_move_success_message: "dfs_move_success_message",
    dfs_depart_help_center_item: "dfs_depart_help_center_item",
    dfs_depart_help_center_title: "dfs_depart_help_center_title",
    dfs_close_reprint_close_btn_msg: "dfs_close_reprint_close_btn_msg",
    dfs_close_reprint_depart_btn_msg: "dfs_close_reprint_depart_btn_msg",
    dfs_reprint_success_msg: "dfs_reprint_success_msg",
    dfs_reprint_success_pallet_msg: "dfs_reprint_success_pallet_msg",
    pallet_id: "pallet_id",
    pallet_location: "pallet_location",
    error_destination_same_as_source: "error_destination_same_as_source",
    error_unexpected_pallet_count: "error_unexpected_pallet_count",
    error_rtooa_not_found: "error_rtooa_not_found",
    reprint_label_title: "reprint_label_title",
    dfs_depart_selector_heading: "dfs_depart_selector_heading",
    dfs_depart_scan_pallet_desc: "dfs_depart_scan_pallet_desc",
    dfs_depart_scan_package_desc: "dfs_depart_scan_package_desc",
    dfc_move_package_subheading: "dfc_move_package_subheading",
    dfs_depart_pallet_swa_packages: "dfs_depart_pallet_swa_packages",
    depart_service_error: "depart_service_error",
    connection_timeout: "connection_timeout",
    incorrect_state_overriden: "incorrect_state_overriden",
    pallet_with_invalid_state_packages: "pallet_with_invalid_state_packages",
    forcefully_departed: "forcefully_departed",
    packages_in_wrong_state: "packages_in_wrong_state",
    acknowledge_pallet_depart: "acknowledge_pallet_depart",
    package_cant_be_stowed: "package_cant_be_stowed"
}

export default STRINGS;